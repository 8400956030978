<template>
  <CRow class="justify-content-center m-0 mt-3">
    <CCol sm="12" lg="5">
      <CSelect
        size="sm"
        :placeholder="$t('label.select')"
        addLabelClasses="required text-right"
        :label="getClientLabel"
        :horizontal="{label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
        :options="ClientOptions"
        :disabled="!Activity || fgInfo"
        v-model="vForm.consignee.ConsigneeId.$model"
        :invalid-feedback="errorMessage(vForm.consignee.ConsigneeId)"
        :is-valid="hasError(vForm.consignee.ConsigneeId)"
        @change="SelectConsignee($event.target.value)"
      >
        <template #append>
          <CButton
            v-if="!fgInfo"
            color="add"
            size="sm"
            :disabled="!Activity"
            v-c-tooltip="{
              content: $t('label.nuevo') + getClientLabel,
              placement: 'top-end',
            }"
            style="padding: 0.15rem 0.4rem; border-radius: 0.2rem;"
            @click="getTpClientId"
          >
            <CIcon name="cil-playlist-add" />
          </CButton>
        </template>
      </CSelect>
    </CCol>
    <CCol sm="12" lg="5">
      <CInput
        disabled
        size="sm"
        v-uppercase
        addLabelClasses="text-right"
        :horizontal="{label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
        :label="getRifLabel"
        v-model.trim="vForm.consignee.ConsigneeRif.$model"
        :invalid-feedback="errorMessage(vForm.consignee.ConsigneeRif)"
        :is-valid="hasError(vForm.consignee.ConsigneeRif)"
        :disabled="fgInfo"
      />
    </CCol>
    <CCol sm="12">
      <CCollapse :show="AddClient">
        <AddCliente
          metalScrap="ClientPortActivity-insert"
          :tpClientId="TpClientId"
          :title="getCollapseTitle"
          @child-refresh="(AddClient=false, TpClientId='')"
          @update-list="getService"
        />
      </CCollapse>
    </CCol>
    <CCol sm="12" lg="5" v-if="!AddClient">
      <CInput
        disabled
        v-uppercase
        size="sm"
        placeholder=""
        addLabelClasses="text-right"
        :label="$t('label.name')"
        :horizontal="{label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
        v-model="vForm.consignee.ConsigneeName.$model"
        :is-valid="hasError(vForm.consignee.ConsigneeName)"
        :invalid-feedback="errorMessage(vForm.consignee.ConsigneeName)"
        :disabled="fgInfo"
      />
    </CCol>
    <CCol sm="12" lg="5" v-if="!AddClient">
      <CInput
        disabled
        v-uppercase
        size="sm"
        placeholder=""
        addLabelClasses="text-right"
        :label="$t('label.address')"
        :horizontal="{label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
        v-model="vForm.consignee.ConsigneeAddress.$model"
        :is-valid="hasError(vForm.consignee.ConsigneeAddress)"
        :invalid-feedback="errorMessage(vForm.consignee.ConsigneeAddress)"
        :disabled="fgInfo"
      />
    </CCol>
    <CCol sm="12" lg="5" v-if="!AddClient">
      <CInput
        disabled
        v-uppercase
        size="sm"
        placeholder=""
        addLabelClasses="text-right"
        :label="$t('label.mainPhone')"
        :horizontal="{label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
        v-model="vForm.consignee.ConsigneePrimaryPhone.$model"
        :is-valid="hasError(vForm.consignee.ConsigneePrimaryPhone)"
        :invalid-feedback="errorMessage(vForm.consignee.ConsigneePrimaryPhone)"
        :disabled="fgInfo"
      />
    </CCol>
    <CCol sm="12" lg="5" v-if="!AddClient">
      <CInput
        disabled
        v-uppercase
        size="sm"
        placeholder=""
        addLabelClasses="text-right"
        :label="$t('label.secondaryPhone')"
        :horizontal="{label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
        v-model="vForm.consignee.ConsigneeSecondaryPhone.$model"
        :is-valid="hasError(vForm.consignee.ConsigneeSecondaryPhone)"
        :invalid-feedback="errorMessage(vForm.consignee.ConsigneeSecondaryPhone)"
        :disabled="fgInfo"
      />
    </CCol>
    <CCol sm="12" lg="5" v-if="!AddClient">
      <CInput
        disabled
        v-uppercase
        size="sm"
        placeholder=""
        addLabelClasses="text-right"
        :label="$t('label.mainEmail')"
        :horizontal="{label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
        v-model="vForm.consignee.ConsigneePrimaryEmail.$model"
        :is-valid="hasError(vForm.consignee.ConsigneePrimaryEmail)"
        :invalid-feedback="errorMessage(vForm.consignee.ConsigneePrimaryEmail)"
        :disabled="fgInfo"
      />
    </CCol>
    <CCol sm="12" lg="5" v-if="!AddClient">
      <CInput
        disabled
        v-uppercase
        size="sm"
        placeholder=""
        addLabelClasses="text-right"
        :label="$t('label.secondaryEmail')"
        :horizontal="{label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
        v-model="vForm.consignee.ConsigneeSecondaryEmail.$model"
        :is-valid="hasError(vForm.consignee.ConsigneeSecondaryEmail)"
        :invalid-feedback="errorMessage(vForm.consignee.ConsigneeSecondaryEmail)"
        :disabled="fgInfo"
      />
    </CCol>
  </CRow>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import UpperCase from '@/_validations/uppercase-directive';
import AddCliente from "@/pages/cliente/add-cliente";

function data() {
  return {
    AddClient: false,
    ClientList: [],
    TpClientId: '',
  };
}

//methods
function getService() {
  this.$store.state.yardManagement.loading = true;
  let requests = [
    this.$http.ejecutar("GET", "Client-list-by-PortActivityId", { PortActivityId: this.Activity }),
  ];
    Promise.all(requests)
     .then((responses) => {
      this.ClientList = responses[0].data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    });
}

function getTpClientId() {
  if (!this.AddClient) {
    this.$store.state.yardManagement.loading = true;
    this.$http.ejecutar("GET", "TpClientPortActivity-list", { PortActivityId: this.Activity })
      .then((responses) => {
        this.TpClientId = responses?.data?.data&&responses.data.data[0] ? responses.data.data[0].TpClientId : '';
        this.AddClient = this.TpClientId ? true : false;
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
      .finally(() => {
        this.$store.state.yardManagement.loading = false;
      });
  } else {
    this.AddClient = false;
  }
}

function SelectConsignee(Value) {
  this.form.consignee.ConsigneeId = Value;
  if (this.form.consignee.ConsigneeId) {
    let data = this.ClientList.find((item) => item.ClientTpId == this.form.consignee.ConsigneeId);
   
    //llenado de campos según el agente aduanal
    this.form.consignee.ClientId = data?.ClientId ?? '';
    this.form.consignee.ConsigneeRif = data?.ClientRif ?? '';
    this.form.consignee.ConsigneeAddress = data?.ClientAddress ?? '';
    this.form.consignee.ConsigneeName = data?.ClientName ?? '';
    this.form.consignee.ConsigneePrimaryPhone = data?.PrimaryPhone ?? '';
    this.form.consignee.ConsigneeSecondaryPhone = data?.SecondaryPhone ?? '';
    this.form.consignee.ConsigneePrimaryEmail = data?.PrimaryEmail ?? '';
    this.form.consignee.ConsigneeSecondaryEmail = data?.SecondaryEmail ?? '';

    this.vForm.consignee.$touch();
  }else{
    
    //reseteo de los campos
    this.form.consignee.ClientId = '';
    this.form.consignee.ConsigneeRif = '';
    this.form.consignee.ConsigneeAddress =  '';
    this.form.consignee.ConsigneeName =  '';
    this.form.consignee.ConsigneePrimaryPhone = '';
    this.form.consignee.ConsigneeSecondaryPhone =  '';
    this.form.consignee.ConsigneePrimaryEmail =  '';
    this.form.consignee.ConsigneeSecondaryEmail =  '';
  }
}

function Reset() {
  this.AddClient = false;
  this.ClientList = [];
  this.TpClientId = '';
}

//computed
function ClientOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.ClientList.map(function(e){
    chart.push({
      label: e.ClientName,
      value: e.ClientTpId,
    })    
  })
  return chart;
}

function getClientLabel() {
  if(this.isExporter)
    return this.$t('label.exporter');
  else if (this.isImporter)
    return this.$t('label.importer');
  else
    return this.$t('label.consignee');
}

function getRifLabel() {
  if(this.isExporter)
    return this.$t('label.rifExporter');
  else if (this.isImporter)
    return this.$t('label.rifImporter');
  else
    return this.$t('label.rifConsignee');
}

function getCollapseTitle() {
  if(this.isExporter)
    return this.$t('label.clientExporter');
  else if (this.isImporter)
    return this.$t('label.clientImporter');
  else
    return this.$t('label.clientConsignee');
}

export default {
  name: 'consignee-tab',
  components: {
    AddCliente,
  },
  data,
  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
    vForm: Object,
    Activity: {
      required: true,
      default: () => '',
    },
    isEdit: Boolean,
    fgInfo: Boolean,
    isExporter: Boolean,
    isImporter: Boolean,
    showModal: Boolean,
    Tab: Boolean,
  },
  mixins: [ModalMixin],
  directives: UpperCase,
  methods: {
    getService,
    getTpClientId,
    SelectConsignee,
    Reset,
  },
  computed:{
    ClientOptions,
    getClientLabel,
    getRifLabel,
    getCollapseTitle,
  },
  watch: {
    showModal: function (Newval) {
      if (Newval&&this.isEdit) {
        this.vForm.consignee.$touch();
      }else{
        this.Reset();
      }
    },
    Tab: function (Newval) {
      if (Newval&&this.Activity) {
        this.getService();
      }
    },
    Activity: function (NewVal, OlVal){
      this.ClientList = [];
    },
  },
};
</script>